import React, { useMemo, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import cx from "classnames";

import getDealsCount from "api/requests/getDealsCount";
import publicConfig from "config/public";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import useOnComponentInView from "hooks/useOnComponentInView";
import { useSelector } from "redux/hooks";
import { getCoordinates, getFormattedLocation } from "redux/selectors/location";

import Button from "components/botanic/Button";
import Logo from "components/Icons/logo.svg";
import Image from "components/Image";
import Placeholder, { Rect } from "components/Placeholder";
import TrackImpression from "components/TrackImpression";

import DealsTakeoverNoResults from "./DealsTakeoverNoResults";

const {
  imgix: { publicUrl },
} = publicConfig;

type DealsTakeoverProps = {
  className?: string;
  ctaLabel?: string; // optional label for the CTA button - defaults to "explore deals"
  gaCategory: string; // the Category to track in Google Analytics for impression/click
  gaLabel?: string /* the Label to track in Google Analytics for impression/click
                      default value is based on the current badge (eg "100+ deals") */;
  signupSource: string; // the 'source' field if  user signs up for email from this component
  handleClick?: () => void; // optional callback to handle click on the deals button, otherwise button will navigate to deals page
  hideLocation?: boolean; // if true, hide the "in Tucson, AZ" text/button
};
const DealsTakeover = ({
  className,
  ctaLabel,
  gaCategory,
  gaLabel,
  signupSource,
  handleClick,
  hideLocation = false,
}: DealsTakeoverProps) => {
  const countryCode = useDomainCountryCode();

  const formattedLocation = useSelector(getFormattedLocation);
  const coordinates = useSelector(getCoordinates);

  const [dealsCount, setDealsCount] = useState<number>();

  const [ref] = useOnComponentInView(
    async () => {
      const { count } = await getDealsCount(coordinates, countryCode);
      setDealsCount(count);
    },
    { triggerOnce: true },
  );

  const badgeCount = useMemo(() => {
    if (!dealsCount || dealsCount === 0) {
      return 0;
    } else if (dealsCount > 0 && dealsCount < 10) {
      return dealsCount;
    } else if (dealsCount >= 10 && dealsCount < 25) {
      return "10+";
    } else if (dealsCount >= 25 && dealsCount < 50) {
      return "25+";
    } else if (dealsCount >= 50 && dealsCount < 100) {
      return "50+";
    } else if (dealsCount >= 100 && dealsCount < 200) {
      return "100+";
    } else if (dealsCount >= 200 && dealsCount < 500) {
      return "200+";
    } else if (dealsCount >= 500) {
      return "500+";
    }
  }, [dealsCount]);

  const analyticsLabel = useMemo(
    () => gaLabel || `${badgeCount} ${dealsCount === 1 ? "deal" : "deals"}`,
    [gaLabel, badgeCount, dealsCount],
  );

  const trackAnalyticsClick = () =>
    trackEvent(gaCategory, "Click", analyticsLabel);

  return (
    <div
      ref={ref}
      className={cx(
        "relative bg-leafly-white py-xxl md:py-section md:min-h-[319px] lg:min-h-[397px]",
        className,
      )}
    >
      <div className="container flex flex-col-reverse md:flex-row items-center">
        <div className="basis-1/2 shrink-0">
          {dealsCount !== undefined ? (
            <TrackImpression
              category={gaCategory}
              label={analyticsLabel}
              className="md:pr-section"
            >
              <div
                className="inline-block font-bold text-white text-sm bg-notification px-sm rounded mb-md"
                data-testid="deals-takeover-badge"
              >
                {dealsCount === 0
                  ? "Deals"
                  : `${badgeCount} ${dealsCount === 1 ? "deal" : "deals"}`}
              </div>
              {dealsCount === 0 ? (
                <DealsTakeoverNoResults
                  gaCategory={gaCategory}
                  signupSource={signupSource}
                />
              ) : (
                <div
                  className="mt-xs text-md"
                  data-testid="deals-takeover-results"
                >
                  <span
                    className="font-extrabold"
                    data-testid="deals-takeover-count"
                  >
                    {dealsCount}{" "}
                    {dealsCount === 1 ? "deal & promo " : "deals & promos "}
                  </span>
                  {formattedLocation && !hideLocation ? (
                    <>
                      just for you in{" "}
                      <Button
                        href={!handleClick ? "/deals" : undefined}
                        variant="text"
                        className="inline-block text-green underline text-left text-md"
                        aria-label="Go to Deals"
                        onClick={
                          handleClick ? handleClick : trackAnalyticsClick
                        }
                        data-testid="deals-takover-location-button"
                      >
                        {formattedLocation}
                      </Button>
                    </>
                  ) : (
                    " near you"
                  )}
                  <Button
                    href={!handleClick ? "/deals" : undefined}
                    className="mt-lg"
                    data-testid="deals-takover-cta-button"
                    onClick={handleClick ? handleClick : trackAnalyticsClick}
                  >
                    {ctaLabel ? ctaLabel : `explore deals`}
                  </Button>
                  <div className="text-xs mt-md text-center md:text-left">
                    exclusively on{" "}
                    <Logo
                      className="deals-exclusive-logo inline flex-shrink-0 text-green"
                      width="38"
                      height="38"
                    />
                  </div>
                </div>
              )}
            </TrackImpression>
          ) : (
            <div className="md:pr-section">
              <Placeholder width="75" height="24" className="mb-md">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="100%" height="32">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder
                width="100%"
                height="50"
                className="mt-lg rounded-full max-w-[322px]"
              >
                <Rect width="100%" height="100%" />
              </Placeholder>
            </div>
          )}
        </div>
        <div className="basis-1/2 mb-xl md:mb-0 shrink-0 min-h-[175px]">
          <Image
            alt=""
            sizes={[288, null, 368, 496, null, 336]}
            src={`${publicUrl}/home_page_deals_flwr_powr.jpg`}
            className="w-full max-w-[288px] md:max-w-none"
          />
        </div>
      </div>
    </div>
  );
};

export default DealsTakeover;
